<template>
  <div>
     <v-dialog
        v-model="chatDialog"
        width="500"
      >  
        <v-card>  
          <v-card-text style="padding: 32px; text-align: center; font-weight: bold;">
            Here can be displayed any of your live or e-mail chat form solutions.
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              style="font-weight: bold;"
              @click="chatDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="formDialog"
        width="500"
      >  
        <v-card>  
          <v-card-text style="padding: 32px; text-align: center; font-weight: bold;">
            Here can be displayed your Tentifly forms.
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              style="font-weight: bold;"
              @click="formDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <div style="cursor: pointer;" @click="showContactButtons = !showContactButtons"> 
    <div
      id="blink"
      class="eye"
      :style="right ? 'right: 18px!important; left: unset!important;' : ''"
    ></div>
    <div
      class="body"
      :style="right ? 'right: 8px!important; left: unset!important;' : ''"
    ></div>
    <div
      class="body-tip"
      :style="right ? 'right: 8px!important; left: unset!important;' : ''"
    ></div>
    <div
      class="iris"
      id="blink"
      :style="right ? 'right: 24px!important; left: unset!important;' : ''"
    ></div>
    <div
      class="eye-reflection"
      id="hide"
      :style="right ? 'right: 24px!important; left: unset!important;' : ''"
    ></div>
    </div>
    <div
      transition="scroll-x-transition"
      v-if="this.talktext && this.talktext.length && !this.firstTimeShow"
      class="talk-bubble tri-right round left-in pluginChatBubble"
      :style="right ? 'right: 72px!important; left: unset!important;' : ''"
    >
      <div class="talktext">
        <p>
          {{ this.talktext }}
        </p>
      </div>
    </div>
    <transition name="fade" style="z-index: 99999999">
    <div v-if="showContactButtons" style="z-index: 999999999">
    <div v-for="(item, i) in renderPluginButtonsList" :key="i + 'button'">
      <v-btn
        v-if="item.firstTimeText && item.firstTimeText.length > 0"
        class="tentifly-contact-intro-bubble"
        :style="
          right
            ? 'right: 56px!important; left: unset!important;' +
              'bottom: ' +
              item.height +
              'px; margin-bottom: 4px;'
            : 'bottom: ' + item.height + 'px; margin-bottom: 4px;'
        "
        small
        rounded
        color="white"
        ><span style="font-weight: bold;">{{ item.firstTimeText }}</span>
      </v-btn>
      <a :href="item.href ? item.href : null"
        ><v-btn
          class="tentifly-contact-button"
          :style="
            right
              ? 'right: 8px!important; left: unset!important;' +
                'bottom: ' +
                item.height +
                'px;'
              : 'bottom: ' + item.height + 'px;'
          "
          fab
          dark
          small
          @click="pluginButtonFunction(item.method)"
          color="#aa004c"
        >
          <v-icon dark>
            {{ item.icon }}
          </v-icon>
        </v-btn>
      </a>
    </div>
    </div>
    </transition>
  </div>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.tentifly-contact-intro-bubble {
  z-index: 99999;
  position: fixed;
  pointer-events: none;
  left: 56px;
}

.tentifly-contact-button {
  z-index: 99999;
  position: fixed;
  left: 8px;
  background: black;
}

@keyframes blink {
  98% {
    transform: none;
    animation-timing-function: ease-in;
  }
  99% {
    transform: translateY(0px) scaleY(-1);
  }
  100% {
    animation-timing-function: ease-out;
  }
}

@keyframes hide {
  100% {
    transform: none;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(0px) scaleY(-1);
  }
  100% {
    animation-timing-function: ease-out;
  }
}

#blink {
  animation: blink 8s infinite;
  animation-delay: 4s;
}

#hide {
  animation: hide 8s infinite;
  animation-delay: 4s;
}

.iris {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  z-index: 999991;
  position: fixed;
  bottom: 24px;
  left: 24px;
  background: black;
}

.eye-reflection {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  z-index: 999992;
  position: fixed;
  bottom: 47px;
  left: 47px;
  background: rgb(255, 255, 255);
}

.body {
  width: 64px;
  height: 64px;
  border-radius: 100%;
  z-index: 99998;
  position: fixed;
  bottom: 8px;
  left: 8px;
  background: #aa004c;
  box-shadow: 6px 3px 5px -1px rgb(0 0 0 / 20%),
    4px 10px 10px 0px rgb(0 0 0 / 14%), 1px 1px 18px 0px rgb(0 0 0 / 12%);
}

.body-tip {
  width: 32px;
  height: 32px;
  border-radius: 0px 0px 5px 0px;
  z-index: 99999;
  position: fixed;
  bottom: 8px;
  left: 40px;
  background: #aa004c;
  box-shadow: 6px 6px 5px -1px rgb(0 0 0 / 10%),
    10px 10px 18px 0px rgb(0 0 0 / 20%);
}

.eye {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  z-index: 999990;
  position: fixed;
  bottom: 18px;
  left: 18px;
  background: white;
}

.pluginIcon {
  position: fixed;
  bottom: 8px;
  left: 8px;
  z-index: 9998;
}

.pluginChatBubble {
  position: fixed;
  bottom: 56px;
  left: 72px;
  z-index: 9999;
  -webkit-box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.talk-bubble {
  display: inline-block;
  height: auto;
  background-color: white;
  margin-right: 8px;
}

/* talk bubble contents */
.talktext {
  padding: 1em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-align: left;
  -webkit-text-size-adjust: 100%;
  word-break: normal;
  tab-size: 4;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "PT Sans", sans-serif;
  -webkit-box-direction: normal;
  pointer-events: auto;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  text-align: initial;
  background-repeat: no-repeat;
  box-sizing: inherit;
  margin: 0;
  color: #424242!important;
}
.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

@media (max-width: 450px) {
}
</style>

<script>
export default {
  methods: {
    pluginButtonFunction(functionName) {
      console.log("clicked")
      if (functionName === "formDialog") {
        this.formDialog = true
        this.chatDialog = false
      } else if (functionName === "chatDialog") {
        this.chatDialog = true
        this.formDialog = false
      }
    },
    setNewTalkText() {
      if (this.firstTextTalk) {
        this.talktext = "";
        this.firstTextTalk = false;
      } else if (this.talktext && this.talktext.length > 0) {
        this.talktext = "";
      } else {
        if (this.pluginChatTexts.length === this.talkTextIndex) {
          this.talkTextIndex = 0;
        }
        this.talktext = this.pluginChatTexts[this.talkTextIndex];
        this.talkTextIndex++;
      }
    },
    setTutorialCookie() {
      var date = new Date();
      date.setSeconds(date.getSeconds() + 31556926);
      var dateString = date.toGMTString();
      var cookieString = "PLUGIN_TUTORIAL_SHOWN=true; expires=" + dateString;
      document.cookie = cookieString;
    },
    getTutorialCookie() {
      let cookies = document.cookie;
      if (cookies.includes("PLUGIN_TUTORIAL_SHOWN=true")) {
        this.firstTimeShow = false;
      } else {
        this.firstTimeShow = true;
      }
    }
  },
  beforeMount() {
    this.getTutorialCookie();
  },
  mounted() {
    this.setTutorialCookie();
    let self = this;
    setInterval(function() {
      self.firstTimeShow = false;
    }, 8000);
    setInterval(function() {
      self.setNewTalkText();
    }, 5000);
  },
  computed: {
    screenWidth: {
      get() {
        return this.$store.state.screenWidth;
      },
      set(newValue) {
        return this.$store.dispatch("setScreenWidth", newValue);
      }
    },
    screenHeight: {
      get() {
        return this.$store.state.screenHeight;
      },
      set(newValue) {
        return this.$store.dispatch("setScreenHeight", newValue);
      }
    },
    renderPluginButtonsList() {
      let list = [];
        for (let i = 0; i < this.pluginButtonsList.length; i++) {
          let obj = this.pluginButtonsList[i];
          obj.height = 80 + 48 * i;
          if (!this.firstTimeShow) {
            delete obj.firstTimeText;
          }
          list.push(obj);
        }
      return list;
    }
  },
  data: () => ({
    pluginChatTexts: [
      "I can talk with your visitors for YOU!",
      "Already selling your stuff. Chinese takeout -50% ONLY TODAY!",
      "You can add me on your web page.",
      "INSERT YOUR CUSTOM TEXT",
      "Bloop, bloop, bloop! That's selling hot!",
      "I sell, rent, collect inquiries and reserve your time.",
      "I WILL make it work.",
      "Fully automating your work like Bender. 100101010",
      "这是中文文本。"
    ],
    talktext: null,
    showContactButtons: true,
    talkTextIndex: 0,
    firstTextTalk: true,
    right: true,
    firstTimeShow: true,
    formDialog: false,
    chatDialog: false,
    pluginButtonsList: [
      {
        icon: "mdi-calendar",
        firstTimeText: "Finally, place order here →",
        method: "formDialog"
      },
      {
        icon: "mdi-chat",
        firstTimeText: "Button to live chat with us →",
        method: "chatDialog"
      },
      {
        icon: "mdi-phone",
        href: "tel:58805006",
        firstTimeText: "Or call us clicking here →"
      },
      {
        icon: "mdi-email",
        href: "mailto:info@tentifly.com",
        firstTimeText: "Send us email →"
      }
    ]
  })
};
</script>
